import React from 'react'
import Helmet from 'react-helmet'
//import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'gatsby'

export default class ShoppingFeeds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  }
  }

  render() {
    return (
      <Layout>
        <Helmet>
            <title>WesData | Feed Analytics</title>
        </Helmet>
        <section className="section" >
            <div className="container">
                <div className="content">
                    <div className="columns">
                        <div className="column is-12">
                            <h1>Feed Analytics</h1>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3">
                            <div class="card">
                                <div class="card-image" style={{backgroundColor:'#406eb7',textAlign:'center',paddingTop:'1em',paddingBottom:'1em'}}>
                                    <FontAwesomeIcon icon="chart-line" size="7x" color="#fff"/>
                                </div>
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">Performance Insights</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                    Getting listed is half the battle.
                                    CPC fees add up quickly. Make sure you're getting real value for your money.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div class="card"><div class="card-content">
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="cloud-download-alt" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Data Collection</h3>
                                    <p>The starting point for performance is collecting the right data.
                                        Most CSEs will provide you with code that you can add to your site to collect data
                                        for clicks and shopping activity that takes place from their site to yours. But each engine has a separate
                                        login, dashboard and reporting format. WesData gives you one place to track activity across
                                        all feed channels.
                                    </p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="tachometer-alt" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Analytics Dashboard</h3>
                                    <p>Armed with a mechanism for tracking activity across all partners,
                                        your next step is to analyze results. We give you one place to monitor performance
                                        across all channels. Quickly see which partners perform and which don't.
                                        Use these insights to optimize ad-spend and stop wasting money.</p>
                                    <img src="/img/powerful-tools.png" alt="WesData Analytics Dashboard" />
                                </div>
                            </div>
                            
                            </div></div>
                        </div>
                        <div className="column is-3">
                        <div class="card">
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">More Services</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                        <ul>
                                            <li>
                                                <Link to="/services/shopping-feeds">Product Feeds</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/partner-management">Partner Management</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/feed-optimization">Feed Optimization</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </Layout>
    )
  }
}
